<template>
  <b-card>


    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="username" placeholder="Username" name="username" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nama" label-for="account-name">
            <b-form-input v-model="nama" name="name" placeholder="Name" />
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
            @click.prevent="editProfile">
            Save changes
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
            @click.prevent="resetForm">
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      userData: JSON.parse(localStorage.getItem('userData')),
      url: localStorage.getItem('baseapi'),
      nama: null,
      username: null,
      profileFile: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    loadData() {
      axios.get(this.url + 'user?id=' + this.userData.iduser,
        {
          headers: {
            'xth': this.userData.token
          }
        }).then(response => {
          this.username = response.data.data.username
          this.nama = response.data.data.nama
        })
    },
    editProfile() {
      axios.put(this.url + 'user/' + this.userData.iduser,
        {
          username: this.username,
          nama: this.nama
        },
        {
          headers: {
            'xth': this.userData.token
          }
        }).then(response => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text:'Successfully changed profile'
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Failed to change profile',
              },
            })
          }
        })
    }
  },
  setup() {

    const refInputEl = ref(null)
    const previewEl = ref(null)


    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
